<template lang="">
  <BCard
    id="invoice-detail-card"
    body-class="p-50"
  >
    <!-- <pre>{{ invoiceData }}</pre> -->
    <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
      <IAmIcon
        icon="infoCircleOutline"
        size="25"
        class="p-1 icon-card"
      />
      <span class="font-medium-3 m-0 fw-700">
        {{ $t('invoice.create.detail.title') }}
      </span>
    </div>

    <div class="d-flex-between my-50">
      <span class="text-airline fw-700">{{ $t('invoice.create.detail.invoiceContent') }}</span>
      <BFormCheckbox
        v-if="!(isAdjust || invoiceData?.referenceAdjustedInvoice)"
        v-model="showTicketNumber"
        class="checkbox-custom"
      >
        {{ $t('invoice.create.detail.showTicketNumber') }}
      </BFormCheckbox>
    </div>

    <!-- ANCHOR REPLACE -->
    <!-- FIXME hiển thị khi trạng thái là REPLACEMENT -->
    <div
      v-if="(isReplace || invoiceData?.referenceReplacedInvoice) && !isAdjust"
      class="text-primary fw-600 mb-50"
    >
      {{ $t('invoice.topRowContent.REPLACE', {
        templateNumber: invoiceData?.referenceReplacedInvoice && !(isReplace || isAdjust) ? invoiceData?.referenceReplacedInvoice?.templateNumber : invoiceData.templateNumber,
        symbol: invoiceData?.referenceReplacedInvoice && !(isReplace || isAdjust) ? invoiceData?.referenceReplacedInvoice?.symbol : invoiceData.symbol,
        invNumber: invoiceData?.referenceReplacedInvoice && !(isReplace || isAdjust) ? invoiceData?.referenceReplacedInvoice?.invNumber : (invoiceData.invOldNumber || invoiceData.invNumber),
        createdAt: convertISODateTime(invoiceData?.referenceReplacedInvoice && !(isReplace || isAdjust) ? invoiceData?.referenceReplacedInvoice?.createdAt : invoiceData.createdAt).date,
      }) }}
    </div>

    <!-- ANCHOR ADJUST -->
    <!-- FIXME hiển thị khi trạng thái là ADJUSTMENT -->
    <div
      v-if="(isAdjust || invoiceData?.referenceAdjustedInvoice) && !isReplace"
      class="d-flex flex-column gap-1"
    >
      <div class="text-primary fw-600 mb-50">
        {{ $t('invoice.topRowContent.ADJUST', {
          templateNumber: invoiceData?.referenceAdjustedInvoice && !(isReplace || isAdjust) ? invoiceData?.referenceAdjustedInvoice?.templateNumber : invoiceData.templateNumber,
          symbol: invoiceData?.referenceAdjustedInvoice && !(isReplace || isAdjust) ? invoiceData?.referenceAdjustedInvoice?.symbol : invoiceData.symbol,
          invNumber: invoiceData?.referenceAdjustedInvoice && !(isReplace || isAdjust) ? invoiceData?.referenceAdjustedInvoice?.invNumber : (invoiceData.invOldNumber || invoiceData.invNumber),
          createdAt: convertISODateTime(invoiceData?.referenceAdjustedInvoice && !(isReplace || isAdjust) ? invoiceData?.referenceAdjustedInvoice?.createdAt : invoiceData.createdAt).date,
        }) }}
      </div>
      <ValidationProvider
        #default="{ errors }"
        :name="$t('invoice.create.detail.adjustReason')"
        rules="required"
      >
        <b-form-group
          label-for="adjust-reason"
          label-cols-sm="3"
        >
          <template #label>
            <div class="d-flex gap-1">
              {{ $t('invoice.create.detail.adjustReason') }}
              <IAmIcon
                icon="infoOutline"
                size="16"
              />
              <span class="text-danger">(*)</span>
            </div>
          </template>
          <b-form-textarea
            id="adjust-reason"
            :value="invoiceData.adjustmentReason"
            :placeholder="$t('invoice.create.detail.phAdjustReason')"
            rows="2"
            :state="errors[0] ? false : null"
            :disabled="!isEditing"
            max-rows="4"
            @input="(val) => $emit('update-invoice', { key: 'adjustmentReason', value: val })"
          />
          <b-tooltip
            v-if="errors[0]"
            variant="danger"
            boundary="window"
            target="adjust-reason"
            triggers="hover"
          >
            {{ errors[0] }}
          </b-tooltip>
        </b-form-group>
      </ValidationProvider>

      <!-- <div
        v-if="![ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT].includes(invoiceData?.adjustmentType)"
        class="d-flex-between my-50"
      >
        <BFormCheckbox
          v-if="isEditing"
          :checked="invoiceData.autoCalculate"
          class="checkbox-custom"
          @change="(val) => $emit('update:invoiceData', { ...invoiceData, autoCalculate: val })"
        >
          {{ $t('invoice.create.detail.adjustAutoCalculate') }}
        </BFormCheckbox>
        <div v-else />

        <BFormCheckbox
          v-model="showTicketNumber"
          class="checkbox-custom"
        >
          {{ $t('invoice.create.detail.showTicketNumber') }}
        </BFormCheckbox>
      </div> -->
    </div>

    <!-- SECTION Table Chi tiết hoá đơn -->
    <b-table
      ref="refInvoiceListTable"
      :style="`max-height: 800px; ${!isEditing ? 'background-color: #f8f8f8' : ''}`"
      :sticky-header="true"
      :class="`position-relative mb-50 ${isInvalidInvoiceItems ? 'border-danger rounded' : ''}`"
      :items="selectedTickets"
      responsive
      primary-key="id"
      show-empty
      :empty-text="$t('invoice.create.detail.pleaseChooseTickets')"
      no-border-collapse
      small
      bordered
      :fields="tableColumnInvoiceCreateDetail"
      :tbody-tr-class="trClassHandle"
    >
      <template
        v-for="column in tableColumnInvoiceCreateDetail"
        #[`head(${column.key})`]="data"
      >
        <span
          :key="column.key"
          class="text-dark text-nowrap d-flex-center"
        >
          <IAmIcon
            v-if="column.key === 'sendPortal' && isInvoiceRequest"
            v-b-tooltip.window.top="'Hiển thị vé trong hoá đơn'"
            icon="checkOutline"
          />
          {{ $t(`invoice.columns.${data.column}`) }}
        </span>
      </template>

      <template #empty="scope">
        <b-alert
          show
          variant="warning"
          class="px-2 py-1 mb-0"
        >
          {{ scope.emptyText }}
        </b-alert>
      </template>

      <!-- ANCHOR STT -->
      <template #cell(no)="row">
        <div
          v-if="row.item.itemType !== GOODS_SERVICE_TYPES.NOTE"
          class="text-right"
        >
          {{ selectedTickets.filter(i => i.itemType !== GOODS_SERVICE_TYPES.NOTE).findIndex(i => i.id === row.item.id) + 1 }}
        </div>
      </template>

      <!-- ANCHOR Tên hàng hoá dịch vụ -->
      <template #cell(name)="{ item }">
        <div>
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.columns.name')"
            rules="required"
            :vid="`name-${item.id}`"
          >
            <BFormInput
              :value="item.name"
              class="d-none"
            />
            <BFormGroup :class="{'is-invalid': errors[0]}">
              <IAmInputTextOrTextarea
                :id="`name-${item.id}`"
                :text.sync="item.name"
                :conditionBreak="(isAdjust || item.name.length > 20)"
                :disabled="!isEditing || isInvoiceRequestAndViewF2"
                style="overflow: auto"
                :name="$t('invoice.columns.name')"
                :state="errors[0] ? false : null"
                :placeholder="$t('invoice.create.detail.phName')"
                :class="{
                  'border-transparent': !errors[0],
                  'fw-700': item.itemType === GOODS_SERVICE_TYPES.NOTE
                }"
                rows="2"
                max-rows="6"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`name-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR số vé -->
      <template #cell(ticketNumber)="{ item }">
        <div
          v-if="item.itemType !== GOODS_SERVICE_TYPES.NOTE"
          style="width: 120px"
        >
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.columns.ticketNumber')"
            :rules="`required|isUniqueTicketNumber:${selectedTickets.map(i => i?.ticketNumber)}|validTicketNumber:${invalidTicketNumbers}`"
            :vid="`ticketNumber-${item.id}`"
          >
            <BFormGroup :class="{'is-invalid': errors[0]}">
              <BFormInput
                :id="`ticketNumber-${item.id}`"
                :value="item?.bookingCode ?? item.ticketNumber"
                :state="errors[0] ? false : null"
                :disabled="!isEditing || isInvoiceRequestAndViewF2"
                :formatter="value => value.trim()"
                :name="$t('invoice.columns.ticketNumber')"
                :class="{ 'border-transparent': !errors[0] }"
                @input="val => { item.ticketNumber = val; onTicketNumberChange(val, item) }"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`ticketNumber-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR Đơn vị tính -->
      <template #cell(unit)="{ item }">
        <div v-if="item.itemType !== GOODS_SERVICE_TYPES.NOTE && (item?.sendPortal ?? true)">
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.columns.unit')"
            rules="required"
          >
            <BFormGroup :class="{'is-invalid': errors[0]}">
              <BFormInput
                :id="`unit-${item.id}`"
                v-model="item.unit"
                :disabled="!isEditing || isInvoiceRequestAndViewF2"
                :state="errors[0] ? false : null"
                :name="$t('invoice.columns.unit')"
                :class="{
                  'border-transparent': !errors[0],
                }"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`unit-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR Số lượng -->
      <template #cell(quantity)="{ item }">
        <div v-if="item.itemType !== GOODS_SERVICE_TYPES.NOTE && (item?.sendPortal ?? true)">
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.columns.quantity')"
            rules="required"
          >
            <BFormGroup :class="{'is-invalid': errors[0]}">
              <BFormInput
                :id="`quantity-${item.id}`"
                v-model="item.quantity"
                :state="errors[0] ? false : null"
                :disabled="!isEditing || isInvoiceRequestAndViewF2"
                :type="'number'"
                :name="$t('invoice.columns.quantity')"
                :class="{
                  'border-transparent': !errors[0],
                  'text-right': true,
                }"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`quantity-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR Đơn giá / số tiền trước thuế -->
      <template #cell(unitPrice)="{ item }">
        <div v-if="item.itemType !== GOODS_SERVICE_TYPES.NOTE && (item?.sendPortal ?? true)">
          <ValidationProvider
            #default="validations"
            :name="$t('invoice.columns.unitPrice')"
            rules="required|min_value:0"
            :vid="`unitPrice-${item.id}`"
          >
            <BFormGroup :class="{'is-invalid': validations.errors[0]}">
              <BFormInput
                :value="item.unitPrice"
                class="d-none"
                disabled
                :name="$t('invoice.columns.unitPrice')"
              />
              <IAmInputMoney
                :id="`unitPrice-${item.id}`"
                :value-money="item.unitPrice"
                :disabled="!isEditing || isInvoiceRequestAndViewF2"
                :canInputNegative="true"
                :custom-class="`${!validations.errors[0] ? 'border-transparent' : ''}`"
                :state="validations.errors[0] ? false : null"
                :on-blur="val => handleUpdateInvoiceItem(item,'unitPrice',val)"
              />
              <b-tooltip
                v-if="validations.errors[0]"
                variant="danger"
                boundary="window"
                :target="`unitPrice-${item.id}`"
                triggers="hover"
              >
                {{ validations.errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR thuế suất -->
      <template #cell(vatRate)="{ item }">
        <div
          v-if="item.itemType !== GOODS_SERVICE_TYPES.NOTE && (item?.sendPortal ?? true)"
          class="custom-v-select-vat-rate"
        >
          <!-- style="width: 150px;" -->
          <BFormGroup>
            <v-select
              v-model="item.valueAddedTax"
              :options="TAX_TYPES"
              :clearable="false"
              class="border-transparent w-100"
              append-to-body
              label="label"
              :disabled="!isEditing || isSingleTaxRateType || isInvoiceRequestAndViewF2"
              :reduce="val => val.label"
              :calculate-position="withPopper"
              @input="(val) => handleUpdateInvoiceItem(item,'vatRate',TAX_TYPES_OBJECT[val])"
            >
              <template #option="data">
                <span>
                  {{ $t(`invoice.taxTypes.${data.label}`) }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $t(`invoice.taxTypes.${data.label}`) }}

                </span>
              </template>

              <template #no-options>
                {{ $t('customer.noOptions') }}
              </template>
            </v-select>
          </BFormGroup>
        </div>
      </template>

      <!-- ANCHOR tiền thuế GTGT -->
      <template #cell(vatAmount)="{ item }">
        <div v-if="item.itemType !== GOODS_SERVICE_TYPES.NOTE && (item?.sendPortal ?? true)">
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.columns.vatAmount')"
            rules="required|min_value:0"
          >
            <BFormGroup>
              <BFormInput
                :value="item.vatAmount"
                class="d-none"
                disabled
                :name="$t('invoice.columns.vatAmount')"
              />
              <IAmInputMoney
                :id="`vatAmount-${item.id}`"
                :value-money="item.vatAmount"
                :disabled="!isEditing || isInvoiceRequestAndViewF2"
                :canInputNegative="true"
                :custom-class="`${!errors[0] ? 'border-transparent' : ''}`"
                :state="errors[0] ? false : null"
                :name="$t('invoice.columns.vatAmount')"
                :on-blur="val => handleUpdateInvoiceItem(item,'vatAmount',val)"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`vatAmount-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR thành tiền -->
      <template #cell(amount)="{ item }">
        <div v-if="item.itemType !== GOODS_SERVICE_TYPES.NOTE && (item?.sendPortal ?? true)">
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.columns.amount')"
            rules="required|min_value:0"
          >
            <BFormGroup :class="{'is-invalid': errors[0]}">
              <BFormInput
                :value="item.amount"
                class="d-none"
                disabled
                :name="$t('invoice.columns.amount')"
              />
              <IAmInputMoney
                :id="`amount-${item.id}`"
                :value-money="item.amount"
                :disabled="!isEditing || isInvoiceRequestAndViewF2"
                :canInputNegative="true"
                :custom-class="`${!errors[0] ? 'border-transparent' : ''}`"
                :state="errors[0] ? false : null"
                :name="$t('invoice.columns.amount')"
                :on-blur="val => handleUpdateInvoiceItem(item,'amount',val)"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`amount-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR hiển trị trong hoá đơn: sendPortal -->
      <template #cell(sendPortal)="{ item }">
        <b-form-checkbox
          v-if="item.itemType === GOODS_SERVICE_TYPES.GOODS_SERVICE"
          v-model="item.sendPortal"
          :disabled="!isEditing"
          @change="onChangSendPortalHandle"
        />
      </template>

      <!-- ANCHOR thao tác -->
      <template #cell(actionNoTitle)="{ item }">
        <div
          v-if="item.itemType !== GOODS_SERVICE_TYPES.NOTE"
          class="d-flex-center gap-1"
        >
          <BButton
            variant="flat-dark"
            class="p-50 rounded-circle"
            :disabled="!isEditing"
            @click="removeLineHandle(item)"
          >
            <IAmIcon
              icon="trash"
              size="18"
              color="#A30D11"
            />
          </BButton>
        </div>
      </template>
    </b-table>
    <div
      v-if="isInvalidInvoiceItems"
      class="text-danger px-50 font-weight-bold"
    >
      {{ $t('invoice.msg.invalidInvoiceItems') }}
    </div>
    <div
      v-if="isEditing && ![ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT].includes(invoiceData?.adjustmentType)"
      class="d-flex-center justify-content-end mb-50 gap-1"
    >
      <BButton
        v-if="!isInvoiceRequestAndViewF2"
        variant="info"
        class="p-50"
        size="sm"
        @click="addLineHandle"
      >
        <HStack center>
          <IAmIcon
            icon="increase"
            color="white"
            size="18"
          />
          {{ $t('invoice.btn.addLine') }}
        </HStack>
      </BButton>

      <BButton
        variant="danger"
        class="p-50"
        size="sm"
        @click="clearAllLine"
      >
        <HStack center>
          <IAmIcon
            icon="trash"
            color="white"
            size="18"
          />
          {{ $t('invoice.btn.clearAll') }}
        </HStack>
      </BButton>
    </div>
    <!-- !SECTION -->

    <!-- SECTION Tổng -->
    <b-row>
      <!-- ANCHOR NOTE -->
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label-for="note-invoice">
          <template #label>
            <div class="font-weight-bolder">
              {{ $t('invoice.create.detail.note') }}
            </div>
          </template>
          <b-form-textarea
            id="note-invoice"
            :value="invoiceData.note"
            :placeholder="$t('invoice.create.detail.phNote')"
            rows="3"
            :disabled="!isEditing"
            max-rows="6"
            @input="(val) => $emit('update-invoice', {key: 'note', value: val})"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex flex-column gap-1"
      >
        <!-- ANCHOR Tổng tiền hàng -->
        <b-row align-v="center">
          <b-col cols="5">
            <span class="font-weight-bolder">{{ $t('invoice.create.detail.totalGoodsAmount') }}</span>
          </b-col>
          <b-col cols="7">
            <ValidationProvider
              #default="{ errors }"
              :name="$t('invoice.create.detail.totalGoodsAmount')"
              rules="required|min_value:0"
              vid="totalGoodsAmount"
            >
              <BFormGroup :class="{'is-invalid': errors[0]}">
                <BFormInput
                  :value="getTotalGoodsAmount"
                  class="d-none"
                  disabled
                  :name="$t('invoice.create.detail.totalGoodsAmount')"
                />
                <IAmInputMoney
                  id="totalGoodsAmount"
                  :value-money="getTotalGoodsAmount"
                  :disabled="!isEditing"
                  :custom-class="`${!errors[0] ? 'border-transparent bg-transparent' : ''}`"
                  :state="errors[0] ? false : null"
                  :name="$t('invoice.create.detail.totalGoodsAmount')"
                  :placeholder="$t('invoice.create.detail.totalGoodsAmount')"
                  :canInputNegative="true"
                  :on-blur="val => handleUpdateInvoiceTotal(val, 'totalGoodsAmount')"
                />
                <b-tooltip
                  v-if="errors[0]"
                  variant="danger"
                  boundary="window"
                  target="totalGoodsAmount"
                  triggers="hover"
                >
                  {{ errors[0] }}
                </b-tooltip>
              </BFormGroup>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- ANCHOR Thuế suất nếu template là 1 thuế suất -->
        <b-row
          v-if="isSingleTaxRateType"
          align-v="center"
        >
          <b-col cols="5">
            <span class="font-weight-bolder">{{ $t('invoice.columns.vatRate') }}</span>
          </b-col>
          <b-col cols="7">
            <BFormGroup class="custom-v-select-vat-rate">
              <v-select
                :value="invoiceData.valueAddedTax"
                :options="TAX_TYPES"
                :clearable="false"
                class="border-transparent w-100"
                append-to-body
                label="label"
                :disabled="!isEditing"
                :reduce="val => val.label"
                :calculate-position="withPopper"
                @input="(val) => {
                  $emit('update:invoiceData', { ...invoiceData, valueAddedTax: val });
                  onVatRateChange(val)
                }"
              >
                <template #option="data">
                  <span>
                    {{ data.value }} %
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ data.value }} %
                  </span>
                </template>

                <template #no-options>
                  {{ $t('customer.noOptions') }}
                </template>
              </v-select>
            </BFormGroup>
          </b-col>
        </b-row>

        <!-- ANCHOR phí thu hộ -->
        <b-row align-v="center">
          <b-col cols="5">
            <span class="font-weight-bolder">{{ $t('invoice.create.detail.collectionFee') }}</span>
          </b-col>
          <b-col cols="7">
            <ValidationProvider
              #default="{ errors }"
              :name="$t('invoice.create.detail.collectionFee')"
              rules="required|min_value:0"
              vid="collectionFee"
            >
              <BFormGroup :class="{'is-invalid': errors[0]}">
                <BFormInput
                  :value="getCollectionFee"
                  class="d-none"
                  disabled
                  :name="$t('invoice.create.detail.collectionFee')"
                />
                <IAmInputMoney
                  id="collectionFee"
                  :value-money="getCollectionFee"
                  :disabled="!isEditing"
                  :custom-class="`${!errors[0] ? 'border-transparent bg-transparent' : ''}`"
                  :state="errors[0] ? false : null"
                  :name="$t('invoice.create.detail.collectionFee')"
                  :placeholder="$t('invoice.create.detail.collectionFee')"
                  :canInputNegative="true"
                  :on-blur="val => handleUpdateInvoiceTotal(val, 'collectionFee')"
                />
                <b-tooltip
                  v-if="errors[0]"
                  variant="danger"
                  boundary="window"
                  target="collectionFee"
                  triggers="hover"
                >
                  {{ errors[0] }}
                </b-tooltip>
              </BFormGroup>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- ANCHOR tổng tiền thanh toán -->
        <b-row align-v="center">
          <b-col cols="5">
            <span class="font-weight-bolder">{{ $t('invoice.create.detail.totalAmount') }}</span>
          </b-col>
          <b-col cols="7">
            <ValidationProvider
              #default="{ errors }"
              :name="$t('invoice.create.detail.totalAmount')"
              vid="totalAmount"
              :rules="[ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT].includes(invoiceData?.adjustmentType) ? '' : 'required|min_value:1'"
            >
              <BFormGroup :class="{'is-invalid': errors[0]}">
                <BFormInput
                  :value="getTotalAmount"
                  class="d-none"
                  disabled
                  :name="$t('invoice.create.detail.totalAmount')"
                />
                <IAmInputMoney
                  id="totalAmount"
                  :value-money="getTotalAmount"
                  :disabled="!isEditing"
                  :custom-class="`${!errors[0] ? 'border-transparent bg-transparent' : ''}`"
                  :state="errors[0] ? false : null"
                  :name="$t('invoice.create.detail.totalAmount')"
                  :placeholder="$t('invoice.create.detail.totalAmount')"
                  :canInputNegative="true"
                  :on-blur="val => handleUpdateInvoiceTotal(val, 'totalAmount')"
                />
                <b-tooltip
                  v-if="errors[0]"
                  variant="danger"
                  boundary="window"
                  target="totalAmount"
                  triggers="hover"
                >
                  {{ errors[0] }}
                </b-tooltip>
              </BFormGroup>
            </ValidationProvider>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- !SECTION -->
  </BCard>
</template>
<script>
import {
  BCard, BCol, BRow, BFormInput, BButton, BTable, BAlert,
  BFormCheckbox, BFormTextarea,
  BFormGroup,
  BTooltip,
} from 'bootstrap-vue'
import {
  ref, computed,
  nextTick, watch,
  toRefs,
} from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'
import { ValidationProvider } from 'vee-validate'
import debounce from 'lodash/debounce'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import store from '@/store'
import useInvoiceHandle, { numberToFixed } from '@/views/invoices/useInvoiceHandle'
import { apiSaleReports } from '@/api'
import { DIFFERENT_FREE, DIFFERENT_RATE } from '@/constants/invoice'

import { onlyUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import { isUniqueTicketNumber, minValue } from '@validations'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormInput,
    BButton,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BAlert,
    BFormGroup,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    vSelect,
    ValidationProvider,
    BTooltip,
    IAmInputTextOrTextarea: () => import('@/components/IAmInputTextOrTextarea.vue'),
  },
  props: {
    invoiceData: {
      type: Object,
      default: () => { },
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    resetInvoice: {
      type: Object,
      default: () => { },
    },
    isReplace: {
      type: Boolean,
      default: false,
    },
    isAdjust: {
      type: Boolean,
      default: false,
    },
    isDuplicate: {
      type: Boolean,
      default: false,
    },
    isInvalidInvoiceItems: {
      type: Boolean,
      default: false,
    },
    isInvoiceRequest: {
      type: Boolean,
      default: false,
    },
    isInvoiceRelease: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      TAX_TYPES, TAX_TYPES_OBJECT, GOODS_SERVICE_TYPES,
      ADJUSTMENT_TYPE,
      DEFAULT_VAT_RATE, TAX_RATE_TYPES,
    } = useInvoiceHandle()

    const {
      invoiceData, isCreate, isAdjust, isDuplicate, // isReplace,
      isInvoiceRequest, isInvoiceRelease,
    } = toRefs(props)
    const showTicketNumber = ref(true)
    const selectedTickets = computed(() => store.getters['app-invoice/getSelectedTickets'])
    const getCollectionFee = computed(() => store.getters['app-invoice/getCollectionFee'])
    const getTotalGoodsAmount = computed(() => store.getters['app-invoice/getTotalGoodsAmount'])
    const getTotalAmount = computed(() => store.getters['app-invoice/getTotalAmount'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isInvoiceRequestAndViewF2 = computed(() => isInvoiceRequest.value && !isRoleF1.value)
    const blankLine = {
      name: '',
      ticketNumber: '',
      unit: 'Vé',
      quantity: 1,
      unitPrice: 0,
      itemType: GOODS_SERVICE_TYPES.GOODS_SERVICE,
      valueAddedTax: 'EIGHT_PERCENT',
      vatAmount: 0,
      amount: 0,
      collectionFee: 0,
      flightType: 'Other',
    }
    const tableColumnInvoiceCreateDetailDefault = computed(() => [
      { key: 'no' },
      { key: 'name', stickyColumn: true, tdClass: `${props.isEditing ? 'bg-white' : ''} width-150` },
      { key: 'ticketNumber' },
      { key: 'unit' },
      { key: 'quantity' },
      { key: 'unitPrice' },
      { key: 'vatRate' },
      { key: 'vatAmount' },
      { key: 'amount' },
      { key: 'sendPortal', forInvoiceRequest: true },
      { key: 'actionNoTitle', stickyColumn: true, class: 'position-right-0' },
    ])
    const tableColumnInvoiceCreateDetail = computed(() => {
      let result = cloneDeep(tableColumnInvoiceCreateDetailDefault.value)
      if (showTicketNumber.value) {
        result = result.filter(col => col.key !== 'ticketNumber')
      }
      if (!isInvoiceRequest.value) {
        result = result.filter(col => col.key !== 'sendPortal')
      }
      return result
    })

    // tính tiền chênh để update
    function calculateNeedToPayAmount(totalAmountNew) {
      const totalAmount = totalAmountNew
      const originAmountWithDifferentFree = selectedTickets.value
        .filter(ticket => ticket.itemType === GOODS_SERVICE_TYPES.GOODS_SERVICE)
        .reduce((a, ticket) => {
          if (ticket.isInvoiceTicket) {
            return a + Math.round(Number(ticket?.amount || 0) + Number(ticket?.collectionFee || 0))
          }
          const differentFreeTotal = (ticket?.numberOfSegments || 0) * DIFFERENT_FREE
          const differenceRequestAmount = isInvoiceRequest.value || isInvoiceRelease.value ? Math.round(ticket.differenceRequestAmount || 0) : 0
          return a + ((Math.round(ticket.originAmount || 0) - differenceRequestAmount + differentFreeTotal) || 0)
        }, 0)
      const totalDifferent = totalAmount - originAmountWithDifferentFree
      return totalDifferent > 0 ? totalDifferent * DIFFERENT_RATE : 0
    }

    // func tính lại giá tổng sau khi sửa
    function updateTotalInStore(data = null, collectionFeeData = null, isSetNeedToPayAmount = true) {
      const arr = (data ?? selectedTickets.value).filter(i => i.itemType !== GOODS_SERVICE_TYPES.NOTE && (i?.sendPortal ?? true))
      const totalGoodsAmount = arr.reduce((acc, cu) => Math.round(acc) + Math.round(cu.amount), 0)
      // eslint-disable-next-line arrow-body-style
      const collectionFee = collectionFeeData ?? arr.reduce((acc, current) => {
        // Lại Bỏ VAT phí thu hộ của VN = 8%
        // const vatOtherTax = current?.airline !== 'VN' ? Number(current?.otherTax) * (current?.flightType === 'Domestic' ? 0.08 : 0) : 0
        // const vatOtherTax = 0
        // return Math.round(acc) + Math.round(current?.collectionFee ?? (current?.saleReport?.collectionFee + current?.saleReport?.otherTax + vatOtherTax) ?? 0)
        // Bỏ cả thuế phí khác
        return Math.round(acc) + Math.round(current?.collectionFee ?? (current?.saleReport?.collectionFee) ?? 0)
      }, 0)

      store.dispatch('app-invoice/setTotalGoodsAmount', totalGoodsAmount)
      store.dispatch('app-invoice/setCollectionFee', collectionFee)
      if (isSetNeedToPayAmount) {
        const needToPayAmount = calculateNeedToPayAmount(totalGoodsAmount + collectionFee)
        store.dispatch('app-invoice/setNeedToPayAmount', needToPayAmount)
      }
    }

    async function clearAllLine() {
      await store.dispatch('app-invoice/clearSelectedTickets')
      updateTotalInStore()
    }

    function initSelectedTickets() {
      const isAdjustIncreaseOrDecrease = [ADJUSTMENT_TYPE.INCREASE_ADJUSTMENT, ADJUSTMENT_TYPE.DECREASE_ADJUSTMENT]
        .includes(invoiceData.value.adjustmentType)
      if (invoiceData.value
        && !isCreate.value
        && (!isAdjust.value || (isAdjust.value && isAdjustIncreaseOrDecrease))
        && !isDuplicate.value
        // && !isReplace.value
      ) {
        const invoiceItems = invoiceData.value.invoiceItems
          .filter(invItem => {
            if (isAdjustIncreaseOrDecrease) {
              return invItem.flightType && invItem.flightType !== 'Other'
            }
            return true
          })
          .map(invItem => {
            const mappingItem = {
              ...invItem,
              vatAmount: Math.round(invItem.vatAmount) || ((Math.round(invItem.amount) - Math.round(invItem.totalBeforeVat))),
              originAmount: Math.round(invItem?.saleReport?.receivables ?? 0),
              // Tiền chênh giữa giá bán F2 với giá nhập F2, dùng cho case F2 gửi yêu cầu xuất hoá đơn cho F1
              // https://discord.com/channels/1054696448110903327/1311605983348523118/1326454824069234689
              differenceRequestAmount: Math.round(Number(invItem?.saleReport?.receivables) - Number(invItem?.saleReport?.tradeCreditors)),
            }
            return mappingItem
          })

        store.dispatch('app-invoice/setSelectedTicketsInDetail', invoiceItems)
        updateTotalInStore(invoiceItems, Math.round(invoiceData.value.collectionFee), false)
      } else if (isAdjust.value && invoiceData.value.adjustmentType === ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT) {
        clearAllLine()
        nextTick(() => {
          const addNote = {
            id: uuidv4(),
            name: '',
            itemType: GOODS_SERVICE_TYPES.NOTE,
            ticketNumber: '',
            unit: GOODS_SERVICE_TYPES.NOTE,
            quantity: 0,
            unitPrice: 0,
            valueAddedTax: DEFAULT_VAT_RATE,
            vatAmount: 0,
            amount: 0,
            originAmount: 0,
            collectionFee: 0,
            numberOfSegments: 0,
          }
          store.dispatch('app-invoice/setSelectedTicketsInDetail', [addNote])
        })
      } else {
        clearAllLine()
      }
    }

    watch(props.resetInvoice, () => {
      initSelectedTickets()
    }, { immediate: true, deep: true })

    function addLineHandle() {
      store.dispatch('app-invoice/addSelectedTicket', cloneDeep({ ...blankLine, id: uuidv4() }))
    }

    async function removeLineHandle(ticket) {
      await store.dispatch('app-invoice/removeSelectedTicket', ticket)
      updateTotalInStore()
    }

    function handleUpdateInvoiceItem(item, key, value, updateTotal = true) {
      if (item.itemType === GOODS_SERVICE_TYPES.NOTE) return
      if (typeof (value) === 'string') value = +value
      if (Math.round(item[key]) === value) return
      const {
        unitPrice, valueAddedTax, vatAmount, amount,
      } = item
      const valueOfValueAddedTax = TAX_TYPES_OBJECT[valueAddedTax]
      switch (key) {
        case 'unitPrice': {
          const temp = (amount - vatAmount)
          if (temp !== value) {
            item.unitPrice = numberToFixed(value)
            item.vatAmount = Math.round((value * valueOfValueAddedTax) / 100)
            item.amount = Math.round(value + item.vatAmount)
          }
          if (updateTotal) updateTotalInStore()
          break
        }

        case 'vatRate': {
          const temp = Math.round((vatAmount / unitPrice) * 100)
          if (temp !== value) {
            item.unitPrice = numberToFixed((amount * 100) / (100 + value))
            item.vatAmount = Math.round(amount - item.unitPrice)
          }
          if (updateTotal) updateTotalInStore()
          break
        }

        case 'vatAmount': {
          item.vatAmount = value
          const temp = (unitPrice * valueOfValueAddedTax) / 100
          if (temp !== value) {
            item.unitPrice = numberToFixed((value * 100) / valueOfValueAddedTax)
            item.amount = Math.round(item.unitPrice + value)
          }

          if (updateTotal) updateTotalInStore()
          break
        }

        case 'amount': {
          item.amount = value
          const temp = unitPrice + vatAmount
          if (temp !== value) {
            item.unitPrice = numberToFixed((value * 100) / (100 + valueOfValueAddedTax))
            item.vatAmount = Math.round(value - item.unitPrice)
            item.amount = value
          }

          if (updateTotal) updateTotalInStore()
          break
        }

        default:
          break
      }
    }
    function handleUpdateInvoiceTotal(value, key) {
      if (typeof value === 'string') value = Math.round(value)
      switch (key) {
        case 'totalGoodsAmount': {
          if (getTotalGoodsAmount.value === value) return
          const differenceAmount = value - getTotalGoodsAmount.value
          const numberOfSelectedTicket = selectedTickets.value
            .filter(({ itemType, sendPortal }) => itemType !== GOODS_SERVICE_TYPES.NOTE && (sendPortal ?? true))
            .length
          const differenceAmountPerTicket = Math.floor(differenceAmount / numberOfSelectedTicket)
          const differenceAmountLastTicket = differenceAmount - (differenceAmountPerTicket * (numberOfSelectedTicket - 1))
          nextTick(async () => {
            await store.dispatch('app-invoice/setTotalGoodsAmount', value)
            selectedTickets.value.forEach((ticket, index, array) => {
              if (!(ticket?.sendPortal ?? true)) return
              if (index < array.length - 1) {
                handleUpdateInvoiceItem(ticket, 'amount', differenceAmountPerTicket + Math.round(ticket.amount), false)
              } else {
                handleUpdateInvoiceItem(ticket, 'amount', differenceAmountLastTicket + Math.round(ticket.amount), false)
              }
            })
            const needToPayAmount = calculateNeedToPayAmount(value + getCollectionFee.value)
            store.dispatch('app-invoice/setNeedToPayAmount', needToPayAmount)
          })
          break
        }

        case 'collectionFee': {
          if (getCollectionFee.value === value) return
          const needToPayAmount = calculateNeedToPayAmount(getTotalGoodsAmount.value + value)
          store.dispatch('app-invoice/setNeedToPayAmount', needToPayAmount)
          store.dispatch('app-invoice/setCollectionFee', value)
          break
        }

        case 'totalAmount': {
          if (getTotalAmount.value === value) return
          handleUpdateInvoiceTotal((value - getCollectionFee.value), 'totalGoodsAmount')
          break
        }

        default:
          break
      }
    }

    watch(selectedTickets, ({ length }, { length: oldLength }) => {
      if (length && (length !== oldLength) && (isInvoiceRequest.value || isInvoiceRelease.value)) {
        const needToPayAmount = calculateNeedToPayAmount(getTotalAmount.value)
        store.dispatch('app-invoice/setNeedToPayAmount', needToPayAmount)
      }
    }, { deep: true })

    watch(invoiceData, (val, oldValue) => {
      if (
        ([ADJUSTMENT_TYPE.INCREASE_ADJUSTMENT, ADJUSTMENT_TYPE.DECREASE_ADJUSTMENT].includes(val?.adjustmentType)
        && ![ADJUSTMENT_TYPE.INCREASE_ADJUSTMENT, ADJUSTMENT_TYPE.DECREASE_ADJUSTMENT].includes(oldValue?.adjustmentType))
        || (val?.adjustmentType === ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT
        && oldValue?.adjustmentType !== ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT)
      ) {
        initSelectedTickets()
      }
    }, { immediate: true, deep: true })

    const isSingleTaxRateType = computed(() => invoiceData.value.template?.taxRateType === TAX_RATE_TYPES.SINGLE)

    const onVatRateChange = value => {
      if (!isSingleTaxRateType.value) return
      selectedTickets.value.forEach(item => {
        item.valueAddedTax = value
        handleUpdateInvoiceItem(item, 'vatRate', TAX_TYPES_OBJECT[value], false)
      })
    }

    // thay đổi thuế suất ngoài bảng HHDV thì thay đổi thuế suất trong bảng HHDV
    watch(isSingleTaxRateType, val => {
      if (val) {
        onVatRateChange(DEFAULT_VAT_RATE)
      }
    })

    async function checkTicketNumber(value) {
      const res = await apiSaleReports.fetchSaleReports({
        ticketNumber: value,
        isInvoiced: false,
      })
      return res.data.items.find(tk => String(tk.ticketNumber) === String(value) && tk.isInvoiced === false)
    }
    const invalidTicketNumbers = ref([])

    // auto get ticket by ticket number
    const onTicketNumberChange = debounce(async (value, ticketItem) => {
      // nếu vé nhập tay vào mà đã có trong list thì ko tự động fill
      if (selectedTickets.value
        .filter(item => item.id !== ticketItem.id)
        .find(item => String(item.ticketNumber) === String(value))
      ) return
      // call api tìm vé nhập tay, tự động fill thông tin
      const ticket = await checkTicketNumber(value)
      if (ticket) {
        await removeLineHandle(ticketItem)
        store.dispatch('app-invoice/setSelectedTicket', {
          item: ticket,
          valueAddedTax: invoiceData.value?.valueAddedTax !== 'OTHER' ? invoiceData.value?.valueAddedTax : undefined,
        })
        invalidTicketNumbers.value = invalidTicketNumbers.value.filter(i => i !== value)
      } else if (value) {
        invalidTicketNumbers.value.push(value)
      }
    }, 300)

    function trClassHandle(item) {
      return {
        'bg-disabled': !props.isEditing,
        'opacity-50': !(item?.sendPortal ?? true),
      }
    }

    function onChangSendPortalHandle() {
      updateTotalInStore()
    }

    return {
      onlyUpperCaseFormatter,
      tableColumnInvoiceCreateDetail,
      formatCurrency,
      convertISODateTime,
      addLineHandle,
      removeLineHandle,
      selectedTickets,
      clearAllLine,
      showTicketNumber,
      handleUpdateInvoiceItem,
      TAX_TYPES,
      TAX_TYPES_OBJECT,
      handleUpdateInvoiceTotal,
      getTotalGoodsAmount,
      getCollectionFee,
      getTotalAmount,
      GOODS_SERVICE_TYPES,
      ADJUSTMENT_TYPE,
      TAX_RATE_TYPES,
      isSingleTaxRateType,
      onVatRateChange,
      onTicketNumberChange,
      isUniqueTicketNumber,
      minValue,
      invalidTicketNumbers,
      isInvoiceRequestAndViewF2,
      trClassHandle,
      onChangSendPortalHandle,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
}
</script>
<style lang="scss" scoped>
.radio-custom,
::v-deep {
  .custom-radio .custom-control-label {
    margin-top: 0;
  }
}

.checkbox-custom ::v-deep {
  .custom-control-label {
    margin-top: 2px;
  }
}

.custom-v-select-vat-rate::v-deep {
  width: 100px;
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__selected-options {
      flex-direction: row-reverse;
    }
  }
}
#invoice-detail-card ::v-deep {
  .table.table-sm th, .table.table-sm td {
    padding: 0.3rem 0.2rem;
  }
  .form-control {
    padding: 0.3rem;
  }
}
</style>
